import React from 'react'
import AboutBlockchain from '../../components/aboutblockchain/AboutBlockchain'


import './blockchainpage.css'

const BlockchainPage = () => {
  return (
    <div className='blockchainpage'>
      <AboutBlockchain />
    </div>
  )
}

export default BlockchainPage

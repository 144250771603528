import React from 'react'
import NoFunMinterGUI from '../../components/nofunmintergui/NoFunMinterGUI'


import './nofunminterpage.css'

const NoFunMinterPage = () => {
  return (
    <div className='pacbuilderpage'>
      <NoFunMinterGUI />
    </div>
  )
}

export default NoFunMinterPage

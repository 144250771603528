import React from 'react'
import NFZFILGUI from '../../components/nfzfilgui/NFZFILGUI'


import './nfzfil.css'

const NFZFIL = () => {
  return (
    <div className='NFZFILPage'>
      <NFZFILGUI />
    </div>
  )
}

export default NFZFIL

import React from 'react'
import StoryGUI from '../../components/storygui/StoryGUI'


import './storypage.css'

const StoryPage = () => {
  return (
    <div className='storypage'>
      <StoryGUI />
    </div>
  )
}

export default StoryPage
